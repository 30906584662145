<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Repository from "@/app/repository/repository-factory";
import gridSetup from "@/components/data/gridSetup";
import { roleService } from "@/app/service/roleService";
import { dataPrepaidCardsStatus } from "@/data/data-prepaid-cards-status";
import {
  dataPrepaidCardsType,
  dataTypeOfBoolean,
} from "@/data/data-prepaid-card-type";
import { dataPrepaidCardsKindOfCard } from "@/data/data-prepaid-cards-kind-of-card";

const PaymentPrepaidCardsRepository = Repository.get(
  "PaymentPrepaidCardsRepository"
);
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");

/**
 * Lista kart
 */
export default {
  page: {
    title: "Lista kart",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      ...gridSetup.data(),
      header: {
        title: "Lista kart",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista kart",
          },
        ],
      },
      fieldsSelectOptions: [],
      reportArray: [],
      rf: {
        "Nazwa firmy": "companyName",
        "Nazwa banku": "bankName",
        "Numer karty": {
          field: "cardNumber",
          callback: (value) => {
            return `${value}`;
          },
        },
        "Nazwa właściciela": "nameOfCardholder",
        "Data wygaśnięcia": "expiryDate",
        "Numer konta bankowego": {
          field: "bankAccountNumber",
          callback: (value) => {
            return `${value}`;
          },
        },
        Status: "status.name",
        Zamknięta: {
          field: "isClosed",
          callback: (value) => {
            return value ? "Tak" : "Nie";
          },
        },
        Typ: "cardType.name",
        Rodzaj: "kindOfCard.name",
        Limit: "spendingLimit",
        "Data zamknięcia": "closeCardDate",
        "Data otwarcia": "openCardDate",
        "Identyfikator właściciela": "cardholderNumber",
      },
      companySelectOptions: [],
      bankSelectOptions: [],
      statusOfCard: dataPrepaidCardsStatus,
      typeOfCard: dataPrepaidCardsType,
      kindOfCard: dataPrepaidCardsKindOfCard,
      isClosedOptions: dataTypeOfBoolean,
    };
  },
  created() {
    this.getStateGridFilters();
    this.getCompanies();
    this.getBank();
  },
  methods: {
    fieldsComputed(fieldList) {
      let fieldsComputed = [];
      let fieldsSelected = [];
      const selectedCount = this.filters.grid.fieldsSelected.length;
      const defaultField = [
        "companyName",
        "bankName",
        "cardNumber",
        "nameOfCardholder",
        "expiryDate",
        "bankAccountNumber",
        "status",
      ];

      // If selected is empty (eg. no save state)
      if (selectedCount === 0) {
        Object.entries(fieldList).forEach(([key, val]) => {
          if (defaultField.includes(val.key)) {
            fieldsSelected.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });

            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        });

        this.filters.grid.fieldsSelected = fieldsSelected;
      }

      // If selected is not empty
      if (selectedCount > 0) {
        Object.entries(this.filters.grid.fieldsSelected).forEach(
          ([key, val]) => {
            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        );
      }

      fieldsComputed.push({ key: "action", label: "Akcje", thClass: "last-col-sticky", tdClass: "last-col-sticky" });
      this.grid.fields = fieldsComputed;
    },
    getCompanies() {
      PaymentCompanyRepository.getAll()
        .then((response) => {
          let companies = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            companies.push({
              text: response.data[i].name,
              value: response.data[i].businessUnitCode,
            });
          }

          this.companySelectOptions = companies;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBank() {
      PaymentBankRepository.getAll()
        .then((response) => {
          let bank = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            bank.push({
              text: response.data[i].name,
              value: response.data[i].shortName,
            });
          }

          this.bankSelectOptions = bank;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gridProvider(ctx) {
      // if (localStorage.getItem("gridPrepaidCardsPaginationState") !== null) {
      //   ctx.currentPage = JSON.parse(
      //     localStorage.getItem("gridPrepaidCardsPaginationState")
      //   );
      // }

      // if (localStorage.getItem("gridPrepaidCardsSearchState") !== null) {
      //   this.filters.grid.filter = JSON.parse(
      //     localStorage.getItem("gridPrepaidCardsSearchState")
      //   );
      // }

      let fltrIsCl =
        this.filters.isClosed && this.filters.isClosed.description
          ? this.filters.isClosed.value
          : "";

      const parameters =
        "?page=" +
        ctx.currentPage +
        "&maxItems=" +
        ctx.perPage +
        "&search=" +
        this.filters.grid.filter +
        "&orderBy=" +
        this.filters.grid.sortBy +
        "&orderDesc=" +
        this.filters.grid.sortDesc +
        "&filterStatus=" +
        this.filters.status.map((e) => e.id).join(",") +
        "&filterType=" +
        this.filters.type.map((e) => e.id).join(",") +
        "&filterKind=" +
        this.filters.kind.map((e) => e.id).join(",") +
        "&isClosed=" +
        fltrIsCl +
        "&filterBank=" +
        this.filters.bank.map((e) => e.value).join(",") +
        "&filterCompany=" +
        this.filters.companies.map((e) => e.value).join(",");
      const promise = PaymentPrepaidCardsRepository.getGrid(parameters);

      return promise
        .then((data) => {
          const fields = data.data.info.fields;
          this.grid.totalRows = data.data.info.countAllItems;

          // if (
          //   localStorage.getItem("gridPrepaidCardsPaginationState") !== null
          // ) {
          //   this.grid.currentPage = JSON.parse(
          //     localStorage.getItem("gridPrepaidCardsPaginationState")
          //   );
          // }

          let fieldsToSelect = [];

          Object.entries(fields).forEach(([key, val]) => {
            if (val.disabled !== true) {
              fieldsToSelect.push({
                text: val.label,
                value: key,
                label: val.label,
                key: key,
                sortable: val.sortable,
              });
            }
          });
          this.fieldsSelectOptions = fieldsToSelect;
          this.fieldsComputed(fieldsToSelect);

          this.reportArray = data.data.data || [];

          return this.reportArray;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    refreshGrid(value, id) {
      this.$refs.table_prepaid_cards.refresh();
    },
    clearGridFiltersAndCols() {
      localStorage.removeItem("gridPrepaidCardsPaginationState");
      localStorage.removeItem("gridPrepaidCardsSearchState");
      this.grid.currentPage = 1;
      this.filters.bank = [];
      this.filters.companies = [];
      this.filters.status = [];
      this.filters.type = [];
      this.filters.kind = [];
      this.filters.isClosed = [];
      this.filters.grid.filter = "";
      this.filters.grid.filterOn = [""];
      this.filters.grid.perPage = 25;
      this.filters.grid.sortBy = "createdAt";
      this.filters.grid.sortDesc = true;
      this.filters.grid.fieldsSelected = [];
      this.saveStateGridFilters();
    },
    clearGridFilters() {
      this.grid.currentPage = 1;
      this.filters.bank = [];
      this.filters.companies = [];
      this.filters.status = [];
      this.filters.type = [];
      this.filters.kind = [];
      this.filters.isClosed = [];
      this.$refs.table_prepaid_cards.refresh();
    },
    saveStateGridFilters() {
      localStorage.setItem(
        "gridPrepaidCardsListState",
        JSON.stringify(this.filters)
      );
      this.$refs.table_prepaid_cards.refresh();

      Vue.swal({
        icon: "success",
        toast: false,
        position: "top",
        title: "SUKCES!",
        text: "Filtry zostały zapisane!",
        showConfirmButton: false,
        timer: 3500,
      });
    },
    getStateGridFilters() {
      if (localStorage.getItem("gridPrepaidCardsListState") !== null) {
        this.filters = JSON.parse(
          localStorage.getItem("gridPrepaidCardsListState")
        );
      }
    },
    clearAllCompanies() {
      this.filters.companies = [];
      this.$refs.table_prepaid_cards.refresh();
    },
    clearAllBank() {
      this.filters.bank = [];
      this.$refs.table_prepaid_cards.refresh();
    },
    clearAllStatus() {
      this.filters.status = [];
      this.$refs.table_prepaid_cards.refresh();
    },
    clearAllType() {
      this.filters.type = [];
      this.$refs.table_prepaid_cards.refresh();
    },
    clearAllKind() {
      this.filters.kind = [];
      this.$refs.table_prepaid_cards.refresh();
    },
  },
  computed: {
    totalFiltersLength() {
      let f1 = this.filters.status ? this.filters.status.length : 0;
      let f2 = this.filters.companies ? this.filters.companies.length : 0;
      let f3 = this.filters.type ? this.filters.type.length : 0;
      let f4 = this.filters.bank ? this.filters.bank.length : 0;
      let f5 = this.filters.kind ? this.filters.kind.length : 0;
      let f6 = this.filters.isClosed && this.filters.isClosed.description ? 1 : 0;
      return f1 + f2 + f3 + f4 + f5 + f6;
    },
    isAdmin() {
      return roleService.isAdmin();
    },
    isProductAdministrator() {
      return roleService.isProductAdministrator();
    },
  },
  watch: {
    // "grid.currentPage": function () {
    //   localStorage.setItem(
    //     "gridPrepaidCardsPaginationState",
    //     JSON.stringify(this.grid.currentPage)
    //   );
    // },
    // "filters.grid.filter": function () {
    //   localStorage.setItem(
    //     "gridPrepaidCardsSearchState",
    //     JSON.stringify(this.filters.grid.filter)
    //   );
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />

    <div class="row" v-if="isAdmin || isProductAdministrator">
      <div class="col-12">
        <b-collapse id="collapse">
          <b-card class="shadow-sm">
            <div class="font-weight-bold" slot="header">Ustawienia kolumn i filtrów</div>
            <div class="form-group">
              <label class="typo__label">Wybierz widoczne kolumny tabeli</label>
              <multiselect v-model="filters.grid.fieldsSelected" :multiple="true" :options="fieldsSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz kolumny" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @input="fieldsComputed(fieldsSelectOptions)">
                <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
              </multiselect>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Wybierz firmę</label>
                  <multiselect v-model="filters.companies" :multiple="true" :options="companySelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz firmę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych firm: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.companies.length" @mousedown.prevent.stop="clearAllCompanies(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Wybierz bank</label>
                  <multiselect v-model="filters.bank" :multiple="true" :options="bankSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz bank" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych banków: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.bank.length" @mousedown.prevent.stop="clearAllBank(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Status</label>
                  <multiselect v-model="filters.status" :multiple="true" :options="statusOfCard" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz status" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych statusów: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.status.length" @mousedown.prevent.stop="clearAllStatus(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Typ</label>
                  <multiselect v-model="filters.type" :multiple="true" :options="typeOfCard" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz typ" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych typów: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.type.length" @mousedown.prevent.stop="clearAllType(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Rodzaj</label>
                  <multiselect v-model="filters.kind" :multiple="true" :options="kindOfCard" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz rodzaj" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych rodzajów: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.type.length" @mousedown.prevent.stop="clearAllKind(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Zamknięty</label>
                  <multiselect v-model="filters.isClosed" :options="isClosedOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                <button type="button" class="btn btn-danger waves-effect waves-light mr-2" v-on:click="clearGridFiltersAndCols">
                  <i class="mdi mdi mdi-trash-can-outline mr-1" /> Usuń ustawienia
                </button>
                <button type="button" class="btn btn-dark waves-effect waves-light mr-2" v-on:click="saveStateGridFilters">
                  <i class="dripicons-pin mr-1" /> Zapisz ustawienia
                </button>
              </div>
              <div class="col text-right">
                <button type="button" class="btn btn-warning waves-effect waves-light" v-on:click="clearGridFilters">
                  <i class="mdi mdi-filter-remove-outline mr-1" /> Wyczyść filtry
                </button>
              </div>
            </div>
          </b-card>
        </b-collapse>
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">Karty</div>
              <div>
                <i class="mdi mdi-format-columns mr-1"></i>Kolumny: <span class="badge badge-pill badge-soft-info font-size-12">{{ filters.grid.fieldsSelected.length + '/' + fieldsSelectOptions.length}}</span>
                <span class="ml-2" v-if="totalFiltersLength > 0"><i class="mdi mdi-filter-outline mr-1"></i>Aktywne filtry: <span class="badge badge-pill badge-soft-success font-size-12">{{ totalFiltersLength }}</span></span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-form inline>
                  <b-form-select v-model="filters.grid.perPage" :options="grid.pageOptions" class="w-auto" />
                  <div class="ml-2 search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filters.grid.filter" :debounce="350" placeholder="Szukaj..." :class="filters.grid.filter !='' ? 'border-success' : ''" />
                      <i class="ti-search search-icon" />
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="col-md-6">
                <div class="text-right mt-2 mt-md-0 mb-2">
                  <b-link v-if="isAdmin || isProductAdministrator" :to="{ name: 'Dodaj kartę' }" class="btn btn-dark waves-effect waves-light mb-2 mr-2">
                    <i class="mdi mdi-plus mr-1" />Dodaj kartę
                  </b-link>
                  <download-excel class="btn btn-warning mb-2 mr-2" :stringifyLongNum="true" :data="reportArray" :fields="rf" worksheet="My Worksheet" name="filename.xls">
                    <i class="mdi mdi-file-excel mr-2"></i>Pobierz Excel
                  </download-excel>
                  <b-button v-b-toggle="'collapse'" class="waves-effect mb-2 collapsed" variant="success">
                    <i class="mdi mdi-filter-outline mr-1"></i>Filtry
                  </b-button>
                </div>
              </div>
            </div>
            <!-- End search -->

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table id="table_prepaid_cards" ref="table_prepaid_cards" :items="gridProvider" :fields="grid.fields" responsive="sm" sticky-header="45vh" sort-icon-left :per-page="filters.grid.perPage" :current-page="grid.currentPage" :sort-by.sync="filters.grid.sortBy" :sort-desc.sync="filters.grid.sortDesc" :filter="filters.grid.filter" :filter-included-fields="filters.grid.filterOn">
                <template v-slot:table-busy>
                  <div class="text-center text-black">
                    <b-spinner class="align-middle" />
                    <strong class="ml-3">Ładowanie danych...</strong>
                  </div>
                </template>

                <template v-slot:cell(isActive)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-success': data.value == true,
                               'badge-soft-danger': data.value == false }">{{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(isAvailable)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-danger': data.value == false,
                               'badge-soft-success': data.value == true }">{{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(isDefault)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-danger': data.value == false,
                               'badge-soft-success': data.value == true }">{{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(isClosed)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-success': data.value == false,
                               'badge-soft-danger': data.value == true }">{{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(typeOfBankAccount)="data">{{ data.item.typeOfBankAccount.name }}</template>

                <template v-slot:cell(cardType)="data">{{ data.item.cardType.name }}</template>
                <template v-slot:cell(kindOfCard)="data">{{ data.item.kindOfCard.name }}</template>
                <template v-slot:cell(status)="data">{{ data.item.status.name }}</template>

                <template v-slot:cell(createdAt)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <template v-slot:cell(action)="row">
                  <ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2" v-if="isAdmin || isProductAdministrator">
                      <a v-b-tooltip.hover.topleft title="Edycja danych">
                        <b-link :to="{ name: 'Edycja karty', params: { id: row.item.prepaidCardId } }" class="grid-action-btn">
                          <i class="font-size-14 far fa-edit" />
                        </b-link>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col">
                <template v-if="grid.totalRows === 0">
                  <div class="d-flex justify-content-center mt-3">
                    <b>Nie znaleziono elementów</b>
                  </div>
                </template>
                <template v-if="grid.totalRows > 0">
                  <div class="dataTables_paginate paging_simple_numbers float-left ml-2">Znaleziono {{ grid.totalRows }} rekordów.</div>
                </template>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-if="grid.totalRows > 0" v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="filters.grid.perPage" first-number last-number />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
